export const checksums = {
  "index": "v3.3.0--dHZAEBIGlO2l9lHxNVOKObCfyV-5K_TcLavFVBUMhxs",
  "docs": "v3.3.0--Qb57bHLskfSwJ845KkfXDYgKlvNo7N3Hr7U4t0s8gtc",
  "blog": "v3.3.0--UXuOOOH_xv9tzw7hn3izknjrCSSZSGHJYFB6vPCD9jU",
  "landing": "v3.3.0--sn-qmjkxcmCLltGp3xNBpB1jP4c46xxo4yEWQoFboms",
  "deploy": "v3.3.0--WtwnS80UDfAcw4HtsTLBavOdsmuRachFbEjomniRveE",
  "support": "v3.3.0--PTGbkKlMWBj2Uj_Fe9NB-C2HMichmmEq-Dtg3B0uGaA",
  "agencies": "v3.3.0--Ow5hyLP6bf667_GSK1IPl8BfE6UwyShy3VaQH4LVnxg",
  "templates": "v3.3.0--y1HGrYiK-ZH_CO4RAx_LqzAa2elYYbMhLC8Gy-KmUjs",
  "videoCourses": "v3.3.0--nKxss8TfSXW6_6ToyEkvmNnagK8Lu1hTY-kAk_pvCcE",
  "designKit": "v3.3.0--axpyVRqLFeIt1Zs3qtabEJoO8jLFMaePLFTBCESsiks",
  "team": "v3.3.0--RwVnfZ4Jqqi3zFTqUzrgdwc-OFU7_LF5P8ozK1q6uEc"
}
export const checksumsStructure = {
  "index": "rbMG1eOiUTNGy_xyk9VPcrt70I6t8ckzwxQB33q9CGk",
  "docs": "Cl2XZ9UuM2VkXN05ktyeqVpSjE5zOk-ACJxbHmNHDjc",
  "blog": "RJa1bT6mb-TLun5xXRwQzQO2OiM574yv3efcV9uHQpM",
  "landing": "45t9gA5AR33S7rbn2z7_FfTJ_XN4FF052AXq67uPcrE",
  "deploy": "PSPDIoGKVQgVA3vA67GTmyfUK6DM_qcZ4VLapjn7MXA",
  "support": "euT2wOQwCvsmZlUf2kN5TQrPUAgcNPAPR4klmLsG13w",
  "agencies": "Y0pqttVW9cRvpf_OAzMbaaJ4T_wnpq_2U6ue4lukic8",
  "templates": "ccHyzsY6NYdRC_TN742UXcW62TYfYPq_X0WfvDRUnSs",
  "videoCourses": "2aS0X0699zUq0WhisdzWqX8Yo7EaRzUp03Nu23ymkio",
  "designKit": "c1_WWfrYTvLNHL_kLbdOQBOaeQKbA3V1JgHgvkHDrwk",
  "team": "fROx0lpAG2F7LelhEMtsAxifeY8fRDRLmWArYlns4rU"
}

export const tables = {
  "index": "_content_index",
  "docs": "_content_docs",
  "blog": "_content_blog",
  "landing": "_content_landing",
  "deploy": "_content_deploy",
  "support": "_content_support",
  "agencies": "_content_agencies",
  "templates": "_content_templates",
  "videoCourses": "_content_videoCourses",
  "designKit": "_content_designKit",
  "team": "_content_team",
  "info": "_content_info"
}

export default {
  "index": {
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "hero": "json",
      "logos": "json",
      "features": "json",
      "foundation": "json",
      "modules": "json",
      "testimonial": "json",
      "deploy": "json",
      "contributors": "json",
      "stats": "json",
      "support": "json",
      "sponsors": "json"
    }
  },
  "docs": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "titleTemplate": "string",
      "links": "json"
    }
  },
  "blog": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "authors": "json",
      "date": "string",
      "draft": "boolean",
      "category": "string",
      "tags": "json"
    }
  },
  "landing": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "json",
      "head": "json",
      "headline": "json",
      "links": "json",
      "cta": "json"
    }
  },
  "deploy": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "componentImg": "string",
      "logoSrc": "string",
      "featured": "boolean",
      "logoIcon": "string",
      "category": "string",
      "nitroPreset": "string",
      "website": "string"
    }
  },
  "support": {
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "title": "string",
      "description": "string",
      "hero": "json",
      "logos": "json",
      "service": "json",
      "expertise": "json",
      "testimonials": "json",
      "project": "json",
      "form": "json"
    }
  },
  "agencies": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "logo": "json",
      "logoFull": "string",
      "link": "string",
      "services": "json",
      "resources": "json",
      "emailAddress": "string",
      "phoneNumber": "string",
      "x": "string",
      "github": "string",
      "linkedin": "string",
      "instagram": "string",
      "color": "json",
      "regions": "json",
      "location": "string"
    }
  },
  "templates": {
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "name": "string",
      "slug": "string",
      "description": "string",
      "repo": "string",
      "demo": "string",
      "purchase": "string",
      "featured": "boolean",
      "badge": "string",
      "screenshotUrl": "string",
      "screenshotOptions": "json"
    }
  },
  "videoCourses": {
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "name": "string",
      "slug": "string",
      "description": "string",
      "url": "string",
      "badge": "string",
      "screenshotOptions": "json",
      "sponsor": "boolean"
    }
  },
  "designKit": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "json",
      "head": "json",
      "headline": "json",
      "links": "json",
      "cta": "json"
    }
  },
  "team": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "json",
      "head": "json",
      "headline": "json",
      "links": "json",
      "cta": "json",
      "users": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}