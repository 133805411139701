
import * as cloudflareRuntime$aczLyF84wgfSDpRmYAE4AZFXApGF1rImfi2bGL4Tqng from '/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxt+image@https+++pkg.pr.new+@nuxt+image@1a5786c_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as ipxRuntime$Bt7nZpLb9I7D42rcskEQmn_45nWk64x7nGmB_3dhre_45nE from '/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxt+image@https+++pkg.pr.new+@nuxt+image@1a5786c_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as noneRuntime$hbkVJoaqYC43VgWhzipHjN0ZhPGjFdxc6MWBuCDid_8 from '/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxt+image@https+++pkg.pr.new+@nuxt+image@1a5786c_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "jpeg",
    "jpg",
    "png",
    "svg",
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$aczLyF84wgfSDpRmYAE4AZFXApGF1rImfi2bGL4Tqng, defaults: {"baseURL":"https://nuxt.com"} },
  ['ipx']: { provider: ipxRuntime$Bt7nZpLb9I7D42rcskEQmn_45nWk64x7nGmB_3dhre_45nE, defaults: {"baseURL":"https://ipx.nuxt.com"} },
  ['none']: { provider: noneRuntime$hbkVJoaqYC43VgWhzipHjN0ZhPGjFdxc6MWBuCDid_8, defaults: {} }
}
        